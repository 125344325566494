import { useCallback, useContext, useEffect, useMemo } from "react";
import find from "lodash/find";
import range from "lodash/range";
import sortBy from "lodash/sortBy";
import { useRouter } from "next/router";
import useSWR from "swr";

import {
  BaseContext,
  ReservationContext,
  ReservationActionTypes,
} from "~context";
// import { useChart, useZoneFilters, usePricing, useReferral } from "~hooks";
import { sdk } from "~lib";
import { SeatsIOSeat, SortTypes } from "~components/reservation/constants";
import {
  CreateOrderInput,
  EventQuery,
  GetTicketsByIdsQuery,
  LineItemInput,
  LineItemType,
  OrderType,
  VenueSeating,
} from "~graphql/sdk";
import { useAccount } from "~hooks/useAccount";
import { useReCaptchaSDK } from "~hooks/useReCaptcha";
import {
  showToast,
  getError,
  handlePromise,
  getCategoryDataFromLabel,
  trackCheckout,
  trackCheckoutV4,
} from "~lib/helpers";

import { getUrl } from "../../lib/helpers/getUrl";
import { useSeatedMultibuy } from "../multibuy/seated";
import { string } from "yup";
import { useReferral } from "~hooks/useReferral";
import { useChart } from "./useChart";
import { usePricing } from "./usePricing";
import { useZoneFilters } from "./useZoneFilters";
import { useReferralCode } from "~hooks/useReferralCode";

const createOrder = async (
  sdkFn: typeof sdk,
  orgId: string,
  input: CreateOrderInput
) => handlePromise(async () => sdkFn({ orgId }).createOrder({ input }));

const getEvent = async (_: string, orgId: string, eventId: string) =>
  sdk({ orgId })
    .event({ id: eventId })
    .then(({ event }) => event);

const getReleaseBySlug = async (
  _: string,
  orgId: string,
  slug: string,
  eventId?: string,
  activeOnly?: boolean
) => {
  const releaseBySlug = await sdk({ orgId }).getReleaseBySlug({
    slug,
    eventId,
    activeOnly,
  });
  return releaseBySlug.getReleaseBySlug;
};

const getReleaseById = async (_: string, orgId: string, id: string) =>
  sdk({ orgId })
    .getReleaseById({ id })
    .then(({ release }) => release);

const getTickets = async (_: string, orgId: string, eventId: string) =>
  sdk({ orgId }).myEventTickets({ id: eventId });

const getMembershipTickets = async (_: string, orgId: string) =>
  sdk({ orgId }).myMembershipTickets();

const getTicketsByIds = async (_: string, orgId: string, ids: string) =>
  sdk({ orgId }).getTicketsByIds({ ids });

const seatsToLineItems = (
  seats: SeatsIOSeat[],
  type: "event" | "membership" | "release",
  oldTickets?: string[]
): LineItemInput[] => {
  getCategoryDataFromLabel;
  return seats.map(({ label, ticketType, category, objectType }, key) => {
    const { zone, section, tags } = getCategoryDataFromLabel(category.label);
    return type === "membership"
      ? {
          type: LineItemType.Membership,
          membershipTypeId: ticketType.value,
          quantity: 1,
          seatLabel: label,
          seatZone: zone,
          seatSection: section,
          seatTags: tags,
          seatType: objectType === "Seat" ? "seat" : "generalAdmission",
          ...(oldTickets?.[key] ? { previousTicketId: oldTickets[key] } : {}),
        }
      : {
          type: LineItemType.Ticket,
          ticketTypeId: ticketType?.value,
          quantity: 1,
          seatLabel: label,
          seatZone: zone,
          seatSection: section,
          seatTags: tags,
          seatType: objectType === "Seat" ? "seat" : "generalAdmission",
          ...(oldTickets?.[key] ? { previousTicketId: oldTickets[key] } : {}),
        };
  });
};

const getUser = async (_: string, orgId: string, userId: string) =>
  sdk({ orgId })
    .user({ id: userId })
    .then(({ user }) => user);

const getBookedSeatsData = async (
  _: string,
  orgId: string,
  id: string,
  type: "event" | "membership"
) =>
  sdk({ orgId })
    .ticketDetailsForBackOfficeOrders({
      ...(type !== "membership" && { eventId: id }),
      ...(type === "membership" && { membershipId: id }),
    })
    //TODO: fix stringified ticket details being passed from the backend and remove the need to
    //convert to JSON, just pass json from the backend to the front end
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .then((res) => JSON.parse(res.ticketDetailsForBackOfficeOrders));

export const useRelease = (
  releaseId: string,
  id: string,
  type: "event" | "membership" | "release",
  activeOnly?: boolean
) => {
  const router = useRouter();
  const { organization } = useContext(BaseContext);
  const { isAdmin, isPOS } = useAccount();
  const { getReferralCampaignByEventId } = useReferral();
  const { recaptchaSdkGenerator } = useReCaptchaSDK();
  const referralCode = useReferralCode();

  const { zones, seats, addons, holdToken, options, dispatch } = useContext(
    ReservationContext
  );

  const { error: eventError, data: event } = useSWR<EventQuery["event"], Error>(
    ["event", organization?.id, id],
    getEvent
  );

  const { data: release, error: releaseError } = useSWR(
    [
      "release",
      organization?.id,
      releaseId,
      router?.query?.eventId,
      activeOnly,
    ],
    !releaseId ? getReleaseById : getReleaseBySlug
  );

  const { data: referralCampaign } = useSWR(
    referralCode ? ["referralCampaign", organization?.id, id] : null,
    getReferralCampaignByEventId
  );

  const { data: user } = useSWR(
    isAdmin && router.query.customer
      ? ["user-order", organization?.id, router.query.customer]
      : null,
    getUser
  );

  const memoizedPosUrl = useMemo(() => {
    const rawPosId = router?.query?.rawpPosId;
    if (!rawPosId) return null;

    const posId = rawPosId === typeof string ? rawPosId : rawPosId[0];
    return `${getUrl(organization?.slug)}/pos/${posId}`;
  }, [router?.query?.posId]);

  const isNonSeated = event?.venue?.seating !== VenueSeating.Seated;

  const setToken = (token: string) =>
    dispatch({ type: ReservationActionTypes.SET_TOKEN, payload: token });

  const updateOptions = (
    key:
      | "amount"
      | "seatSelectType"
      | "zoneType"
      | "coverType"
      | "selectedSort"
      | "selectedZone"
      | "selectedSection"
      | "validSeatSelection",
    value: string | number | boolean
  ) =>
    dispatch({
      type: ReservationActionTypes.UPDATE_OPTIONS,
      payload: { [key]: value },
    });

  const isChangingSeats = !!router?.query?.ownedTickets;

  const { data: ownedTicketsData, error: ownedTicketsError } = useSWR(
    organization?.id && router?.query?.ownedTickets
      ? ["tickets", organization?.id, id]
      : null,
    getTickets
  );

  const {
    data: ownedMembershipTicketsData,
    error: ownedMembershipTicketsError,
  } = useSWR(
    organization?.id && router?.query?.ownedTickets
      ? ["membershipTickets", organization?.id]
      : null,
    getMembershipTickets
  );
  const { data: ownedTicketData, error: ownedTicketError } = useSWR(
    organization?.id && router?.query?.ownedTickets
      ? ["ownedTickets", organization?.id, router?.query?.ownedTickets]
      : null,
    getTicketsByIds
  );

  const { activePromotions } = useSeatedMultibuy(
    release?.event?.multiBuyPromotions,
    seats
  );

  const changeTickets = useMemo(() => {
    let tix: GetTicketsByIdsQuery["getTicketsByIds"] = [];

    tix = ownedTicketData?.getTicketsByIds;

    return Array.isArray(tix)
      ? tix?.filter(({ id }) =>
          Array.isArray(router?.query?.ownedTickets)
            ? router?.query?.ownedTickets?.includes(id)
            : router?.query?.ownedTickets.split(",").includes(id)
        )
      : [];
  }, [
    router?.query?.ownedTickets,
    ownedTicketsData,
    ownedMembershipTicketsData,
    ownedTicketData,
  ]);

  const priceComp = isChangingSeats
    ? changeTickets?.reduce(
        (acc, { lineItem: { price, quantity } }) => (acc += price * quantity),
        0
      )
    : undefined;

  useEffect(() => {
    if (changeTickets?.length) {
      updateOptions("amount", changeTickets.length);
    }
  }, [changeTickets]);

  useEffect(() => {
    if (router?.query?.posId) {
      if (localStorage.getItem("posId")) {
        localStorage.removeItem("posId");
        localStorage.setItem("posId", router?.query?.posId as string);
      } else {
        localStorage.setItem("posId", router?.query?.posId as string);
      }
    }
  }, [router?.query?.posId]);

  const releasedZones = release?.releaseZones;

  useEffect(() => {
    if (release && !release?.isActive && !isAdmin) {
      showToast("This is release is not active yet", "error");
      void router.replace("/");
      return;
    }
  }, [release]);

  const { filteredZones } = useZoneFilters({
    zones,
    releasedZones,
    opts: {
      coverType: options?.coverType,
      alcoholFreeZone: options?.zoneType,
    },
  });

  const nonSeatedFilteredZone = useMemo(() => {
    return releasedZones?.map((releasedZone) => releasedZone.zone);
  }, [releasedZones]);

  const { pricedZones, getPrice } = usePricing({
    filteredZones: isNonSeated ? nonSeatedFilteredZone : filteredZones,
    releasedZones,
    type,
    isAdmin,
    activePromotions,
  });

  let sortedZones = sortBy(pricedZones, (zone) => zone.displayOrder ?? 0);

  if (options.selectedSort === SortTypes.PRICE) {
    sortedZones = sortBy(pricedZones, ({ priceRange }) => [
      priceRange.min,
      priceRange.max,
    ]);
  }

  const onSelectCategoryLabel = useCallback(
    (categoryLabel: string | undefined) => {
      if (!categoryLabel || !pricedZones) {
        return;
      }

      const newOptions: any = {
        selectBestOnChange: false,
      };
      const {
        zone: zoneLabel,
        section: sectionLabel,
      } = getCategoryDataFromLabel(categoryLabel);

      const zone = find(pricedZones, { name: zoneLabel });

      if (zone) {
        newOptions.selectedZone = zone;

        if (zone?.sections?.length > 0) {
          newOptions.selectedSection = find(zone.sections, {
            name: sectionLabel,
          });
        }

        dispatch({
          type: ReservationActionTypes.UPDATE_OPTIONS,
          payload: newOptions,
        });
      }
    },
    [pricedZones]
  );

  const { data: tooltipData, error: tooltipDataError } = useSWR(
    isAdmin && event?.id
      ? ["tooltipData", organization?.id, event?.id, "event"]
      : null,
    getBookedSeatsData
  );

  const setIsValiSeatSelection = (value: boolean) => {
    updateOptions("validSeatSelection", value);
  };

  const { chart } = useChart({
    ...{ seatsEventKey: event?.seatsEventKey },
    ...{ venue: release?.event?.venue },
    amount: options?.amount,
    seatSelectType: options?.seatSelectType,
    currentSeats: seats,
    currentSection: options?.selectedSection,
    currentZone: options?.selectedZone,
    selectBestOnChange: options?.selectBestOnChange,
    selectableZones: pricedZones,
    holdToken,
    type,
    onSelectCategoryLabel,
    setHoldToken: setToken,
    changeTickets,
    tooltipData,
    tooltipDataLoading: isAdmin && !tooltipData && !tooltipDataError,
    validSeatSelection: options?.validSeatSelection,
    setValidSelection: setIsValiSeatSelection,
  });

  useEffect(() => {
    if (router?.query && router.query?.session !== "continue") {
      dispatch({ type: ReservationActionTypes.RESET_OPTIONS });
    }
  }, [router?.query?.session]);

  const handleOrderCreation = async (
    errorCallback?: (errorMessage: string) => void
  ) => {
    if (seats && holdToken) {
      let orderType = Object.values(OrderType).includes(
        router.query?.type as OrderType
      )
        ? (router.query?.type as OrderType)
        : undefined;

      if (changeTickets?.length && !router?.query?.type) {
        orderType = OrderType.ChangeSeats;
      }

      const releasePasswordId =
        sessionStorage.getItem("releasePasswordId") || undefined;

      const input = {
        lineItems: [
          ...seatsToLineItems(
            seats,
            type,
            changeTickets?.map((changeTicket) => changeTicket.id)
          ),
          // eslint-disable-next-line no-unsafe-optional-chaining
          ...release?.releaseEventAddons
            ?.map(({ eventAddon: addon }) => {
              if (addon.isActive && addons?.[addon.id]?.value) {
                return {
                  type: LineItemType.Addon,
                  addonId: addon.id,
                  quantity: addons?.[addon.id]?.value,
                };
              }

              return undefined;
            })
            ?.filter((a) => !!a),
        ]?.filter((lineItem) => {
          return lineItem.quantity > 0;
        }),
        holdToken,
        orderType,
        releaseId: release?.id,
        ...(releasePasswordId && { releasePasswordId }),
        ...(activePromotions?.[0]?.promotion?.id && {
          multiBuyId: activePromotions?.[0]?.promotion?.id,
        }),
        referralId: referralCampaign?.getReferralCampaignByEventId?.isActive
          ? referralCode
          : null,
      };

      const cartRecovery = router.query.cartRecovery === "true";

      const { error, data: orderData } = await createOrder(
        await recaptchaSdkGenerator("createOrder"),
        organization?.id,
        {
          ...input,
          ...(router.query.customer && {
            userId: router.query.customer as string,
          }),
          ...(router?.query?.posId && {
            posId: router?.query?.posId as string,
          }),
          ...(cartRecovery && {
            isCreatedFromRecoveredCart: cartRecovery,
          }),
        }
      );

      if (error) {
        if (errorCallback) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          errorCallback(getError(error, "graphQL"));
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          showToast(getError(error, "graphQL"), "error");
        }
        return false;
      }

      trackCheckout(orderData.createOrder, organization);
      trackCheckoutV4(orderData.createOrder, organization);
      await router.push(
        "/checkout/[orderId]",
        `/checkout/${orderData.createOrder.id}`
      );
      return true;
    }

    showToast("No seat selection found", "error");
    return false;
  };

  const handleGAOrderCreation = async (
    items: any[],
    addonItems?: any[],
    multiBuyId?: string,
    referralId?: string,
    errorCallback?: (errorMessage: string) => void
  ) => {
    const orderType = Object.values(OrderType).includes(
      router.query?.type as OrderType
    )
      ? (router.query?.type as OrderType)
      : undefined;

    const releasePasswordId =
      sessionStorage.getItem("releasePasswordId") || undefined;

    const addonLineItems =
      addonItems?.map((addon) => ({
        quantity: addon.quantity,
        type: LineItemType.Addon,
        addonId: addon.id,
      })) || [];

    const input = {
      lineItems: [
        ...items.map((ticket) => ({
          quantity: ticket.quantity,
          ...((type === "event" || type === "release") && {
            ticketTypeId: ticket.id,
            type: LineItemType.Ticket,
          }),
          ...(type === "membership" && {
            membershipTypeId: ticket.id,
            type: LineItemType.Membership,
          }),
          seatZone: ticket.zoneId,
        })),
        ...addonLineItems,
      ]?.filter((lineItem) => {
        return lineItem.quantity > 0;
      }),
      multiBuyId,
      orderType,
      ...(type === "event"
        ? {
            releaseId: release?.id,
          }
        : type === "membership"
        ? {
            membershipId: release?.id,
          }
        : {
            releaseId: release?.id,
          }),
      ...(releasePasswordId && { releasePasswordId }),
      referralId: referralCampaign?.getReferralCampaignByEventId?.isActive
        ? (router?.query?.referralCode as string)
        : null,
    };

    const cartRecovery = router.query.cartRecovery === "true";

    const { error, data: orderData } = await createOrder(
      await recaptchaSdkGenerator("createOrder"),
      organization?.id,
      {
        ...input,
        ...(router.query.customer && {
          userId: router.query.customer as string,
        }),
        ...(router?.query?.posId && {
          posId: router?.query?.posId as string,
        }),
        ...(cartRecovery && {
          isCreatedFromRecoveredCart: cartRecovery,
        }),
      }
    );

    if (error) {
      if (errorCallback) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        errorCallback(getError(error, "graphQL"));
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        showToast(getError(error, "graphQL"), "error");
      }
      return false;
    }

    trackCheckout(orderData.createOrder, organization);
    trackCheckoutV4(orderData.createOrder, organization);
    await router.push(
      "/checkout/[orderId]",
      `/checkout/${orderData.createOrder.id}`
    );
    return true;
  };

  const minSeatAmountOption = release ? release.minPurchaseQuantity : 1;
  let maxSeatAmountOption = release ? release.maxPurchaseQuantity : 10;

  if (isAdmin || isPOS) {
    maxSeatAmountOption = 100;
  }

  const seatAmountOptions = range(
    Math.max(minSeatAmountOption - 1, 0),
    Math.min(maxSeatAmountOption, 100)
  ).map((i) => ({
    value: i + 1,
    label: `${i === 0 ? `${i + 1} person` : `${i + 1} people`}`,
  }));

  return {
    changeTickets,
    chart,
    dispatch,
    error:
      type === "event"
        ? eventError || ownedTicketsError || ownedTicketError
        : ownedMembershipTicketsError || ownedTicketError,
    event,
    getPrice,
    handleGAOrderCreation,
    handleOrderCreation,
    holdToken,
    isLoadingZones: !zones,
    isLoading:
      !event &&
      !eventError &&
      (!isChangingSeats ||
        (type === "event"
          ? ownedTicketsData && !ownedTicketsError
          : ownedMembershipTicketsData && !ownedMembershipTicketsError)),
    options,
    release,
    seats,
    selectableZones: sortedZones,
    setToken,
    updateOptions,
    user,
    priceComp,
    memoizedPosUrl,
    releaseError,
    referralCampaign:
      referralCampaign?.getReferralCampaignByEventId?.isActive &&
      router?.query?.referralCode
        ? referralCampaign
        : null,
    seatAmountOptions,
  };
};
