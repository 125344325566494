import { useRouter } from "next/router";
import { useEffect, useState } from "react";

// For SSR.
const hasSessionStorage = typeof sessionStorage !== "undefined";

/**
 * Usage:
 * Place the hook on any page that is an entry point with referralCode in the query params:
 * useReferralCode()
 *
 * To retrieve the value from anywhere else:
 * const referralCode = useReferralCode();
 */
export const useReferralCode = (): string => {
  const router = useRouter();
  const referralCodeInQueryParams = router?.query?.referralCode;

  const [referralCode, setRefferalCode] = useState(
    typeof referralCodeInQueryParams === "string"
      ? referralCodeInQueryParams
      : hasSessionStorage
      ? sessionStorage.getItem("referralCode")
      : null
  );

  useEffect(() => {
    if (typeof referralCodeInQueryParams === "string") {
      setRefferalCode(referralCodeInQueryParams);
      hasSessionStorage &&
        sessionStorage.setItem("referralCode", referralCodeInQueryParams);
    }
  }, [referralCodeInQueryParams]);

  return referralCode;
};
